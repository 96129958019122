<template>
    <div class="show-not-found background-not-supported">
        <div class="container-flex">
            <img :src="logoCaredokter" />
            <template v-if="isLoading">
                <div
                    class="show-loading-not-support d-flex align-items-end justify-content-center"
                >
                    <div
                        class="spinner-grow spinner-grow-sm c-pink-care-doctor mr-2"
                        role="status"
                    >
                        <span class="sr-only"></span>
                    </div>
                    <div
                        class="spinner-grow spinner-grow-sm c-pink-care-doctor mr-2"
                        role="status"
                    >
                        <span class="sr-only"></span>
                    </div>
                    <div
                        class="spinner-grow spinner-grow-sm c-pink-care-doctor"
                        role="status"
                    >
                        <span class="sr-only"></span>
                    </div>
                </div>
                <label class="font-wait mt-2">Mohon tunggu</label>
                <label class="font-access"
                    >Kami sedang mendaftarkan account Anda<br />Proses ini dapat
                    memakan waktu hingga beberapa menit
                </label>
            </template>
            <template v-else>
                <img :src="errorLaptop" />
                <label class="font-forbidden">Account not connected</label>
                <label class="font-access"
                    >Anda tidak memiliki akses ke halaman ini</label
                >
                <button class="btn btn-primary-caredokter" @click="adminContact">
                    Kontak Admin
                </button>
            </template>
        </div>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const doctorRepo = RepositoryFactory.get("doctor");
import laptopError from "@/assets/img/laptop-error.png";
import logoCaredokter from "@/assets/img/caredokter.png";
import logOut from "@/services/logOut.service";
export default {
    data() {
        return {
            errorLaptop: `${laptopError}`,
            logoCaredokter: `${logoCaredokter}`,
            isLoading: true,
        };
    },
    mounted() {
        const that = this;

        setTimeout(async () => {
            const query = that.$route.query;
            if (query.id && query.signatureKey) {
                await that
                    .postDoctorLogin(query)
                    .then((_data) => {
                        that.$router
                            .push({ name: "dashboard" })
                            .catch(() => {});
                    })
                    .catch(() => {
                        this.isLoading = false;
                        // that.$router.push({name: 'error'}).catch(() => {})
                    });
            }
        }, 1000);
    },
    methods: {
        logout() {
            logOut.logout();
        },
        postDoctorLogin(query) {
            return doctorRepo.postDokterLogin(query);
        },
        adminContact () {
          window.keycloak.loadUserInfo()
            .then(_resp => {
              this.getN8N(_resp.preferred_username,
              'Akun yang anda tautkan sudah terhubung atau bermasalah',
               _resp.email,
                 _resp.sub,
               _resp.tenant_id)
              this.logout()
            })
        },
        popupCenter ({url, title, w, h}) {
          // Fixes dual-screen position                             Most browsers      Firefox
          const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
          const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

          const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
          const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

          const systemZoom = width / window.screen.availWidth;
          const left = (width - w) / 2 / systemZoom + dualScreenLeft
          const top = (height - h) / 2 / systemZoom + dualScreenTop
          const newWindow = window.open(url, title, 
            `
            scrollbars=yes,
            width=${w / systemZoom}, 
            height=${h / systemZoom}, 
            top=${top}, 
            left=${left}
            `
          )

          if (window.focus) newWindow.focus();
      },
        getN8N (username, message, email,  sub, tenant) {
          const origin = window.location.origin
          const url = `https://n8n.mandayamedical.group/webhook/KontakAdmin?un=${username}&msg=${message}&em=${email}&sub=${sub}&tenant=${tenant}&origin=${origin}`
          this.popupCenter({url, title: '_blank', w: 600, h: 200});   
        }
    },
};
</script>

<style></style>
