<template>
    <div
        class="kt-grid kt-grid--ver kt-grid--root login-body h-100"
        :style="{ background: image }"
    >
        <div
            class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-login-v1"
            id="kt_login_v1"
        >
            <!--begin::Item-->
            <div class="kt-grid__item">
                <!--begin::Heade-->
                <div class="kt-login-v1__head">
                    <div class="kt-login-v1__logo">
                        <a href="#" class="text-white h3"> Mandaya Hospital </a>
                    </div>
                </div>
                <!--begin::Head-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div
                class="kt-grid__item kt-grid kt-grid--ver kt-grid__item--fluid"
            >
                <!--begin::Body-->
                <div class="kt-login-v1__body">
                    <!--begin::Section-->
                    <div class="kt-login-v1__section">
                        <div class="kt-login-v1__info">
                            <h3 class="kt-login-v1__intro">
                                “Semangat untuk penyembuhan”
                            </h3>
                            <p>
                                Indonesia telah membuat kemajuan yang cukup
                                besar dalam penanganan kesehatan dalam beberapa
                                dekade terakhir.
                            </p>
                        </div>
                    </div>
                    <!--begin::Section-->
                    <!--begin::Separator-->
                    <div class="kt-login-v1__seaprator"></div>
                    <!--end::Separator-->
                    <!--begin::Wrapper-->
                    <div class="kt-login-v1__wrapper">
                        <div class="kt-login-v1__container">
                            <h3 class="kt-login-v1__title">Sign In</h3>
                            <div class="kt-login-v1__form kt-form">
                                <div class="mb-2"  v-for="(item, index) in loginSSO" :key="index">
                                <button 
                                    v-if="item.enabled"
                                   class="btn btn-block btn-primary"
                                   @click="loginAs(item.alias)">
                                  {{item.displayName}}
                                </button>
                                </div>
                            </div>
                            <!--begin::Form-->
                            <!-- <form
                                v-if="modeForm === 'login'"
                                class="kt-login-v1__form kt-form"
                                @submit.prevent="login"
                            >
                                <div class="form-group">
                                    <input
                                        class="form-control"
                                        type="text"
                                        placeholder="Username"
                                        name="username"
                                        v-model="username"
                                        autocomplete="off"
                                    />
                                </div>
                                <div class="form-group mb-2">
                                    <input
                                        class="form-control"
                                        :type="
                                            isTypePassword ? 'password' : 'text'
                                        "
                                        placeholder="Password"
                                        name="password"
                                        v-model="password"
                                        autocomplete="off"
                                    />
                                    <i
                                        class="fa"
                                        :class="[eyeClass, 'position-eye']"
                                        @click="changeEye"
                                    ></i>
                                </div>
                                <span v-if="isError" class="ml-2 text-danger">{{
                                    isError
                                }}</span>
                                <div
                                    class="kt-login-v1__actions justify-content-end"
                                >
                                
                                    <button
                                        type="submit"
                                        class="btn btn-pill btn-elevate"
                                        id="goSubmit"
                                    >
                                        Sign In
                                        <div
                                            v-if="isLoading"
                                            class="spinner-border spinner-login"
                                        >
                                            <span class="sr-only"></span>
                                        </div>
                                    </button>
                                </div>
                            </form> -->
                            <!-- <div
                                v-else-if="modeForm === 'tenant'"
                                class="kt-login-v1__form kt-form"
                               
                            >
                                <div class="form-group">
                                    <label
                                        class="text-white "
                                        for="exampleFormControlSelect2"
                                        >Select Hospital</label
                                    >
                                    <select
                                        multiple
                                        class="form-control overflow-hidden"
                                        v-model="selectTenant"
                                    >
                                        <option
                                            v-for="(
                                                tenant, index
                                            ) in optionTenant"
                                            :key="index"
                                            :value="tenant.id"
                                        >
                                            {{ tenant.name }}
                                        </option>
                                    </select>
                                </div>
                                <div
                                    class="kt-login-v1__actions justify-content-around"
                                >
                                <button
                                        type="button"
                                        class="btn btn-pill btn-elevate mr-2"
                                        @click="backTo"
                                    >
                                        back
                                    </button>
                                    <button
                                        type="submit"
                                        class="btn btn-pill btn-elevate"
                                        @click="goTo"
                                    >
                                        Sign In
                                        <div id="spinner-btn">
                                            <span class="sr-only"></span>
                                        </div>
                                    </button>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="kt-grid__item">
                <div class="kt-login-v1__footer">
                    <div class="kt-login-v1__menu">
                        <a href="https://www.caredr.org/mobile" class="mr-4"
                            >Download Care Dokter</a
                        >
                        <a href="https://caredokter.com/privacy-policy/">Privacy Policy</a>
                    </div>
                    <div class="kt-login-v1__copyright">
                        <a
                            href="https://mandayahospitalgroup.com/"
                            target="_blank"
                            >&copy; Mandaya Hospital</a
                        >
                    </div>
                </div>
            </div>
            <!--end::Item-->
        </div>
    </div>
</template>

<script>
import background from "@/assets/img/bg_1.jpg";
import LoginRepository from "@/repositories/LoginRepository";
export default {
    data() {
        return {
            image: `url('${background}')`,
            eyeClass: "fa-eye-slash",
            isTypePassword: true,
            isLoading: false,
            isError: "",
            modeForm: "login",
            selectTenant: [],
            optionTenant: [],
            username: '',
            password: '',
            loginSSO: []
        };
    },
    created() {
        this.getLoginData()
        // this.loginSSO = [{"displayName":"Login As Puri","hideOnLogin":false,"alias":"keycloak-oidc-puri","enabled":true},{"displayName":"Login As Karawang","hideOnLogin":false,"alias":"keycloak-oidc-karawang","enabled":true},{"displayName":"Login As MHG","hideOnLogin":false,"alias":"keycloak-oidc","enabled":true}]
    },
    methods: {
        getLoginData() {
            LoginRepository.getDataSSO().then(_resp => {
               if(_resp.status === 200) {
                const list = _resp.data.map(_item => {
                    return {
                        displayName: _item.tenant.name ? _item.tenant.name : '-',
                        alias: _item.alias,
                        enabled: _item.enabled,
                        tenant_id: _item.tenant.id ?  _item.tenant.id : '-'
                    }
                })
                localStorage.setItem('idpHint', JSON.stringify(list))
                this.loginSSO = list
               }
            })
        },
        loginAs(hint) {
            localStorage.setItem('selectedRealm', hint)
             keycloak.login({idpHint: hint})
        },
        login () {
            keycloak.login()
        },
        // login() {
        //     if (this.isLoading) return;
        //     this.isError = "";
        //     if(!this.username || !this.password) return
        //     this.isLoading = true;
        //     const data = {
        //         username: this.username,
        //         password: this.password
        //     }
        //     LoginRepository.login(data)
        //         .then((_data) => {
        //             this.isLoading = false;
        //             const res = _data.access_token
        //             localStorage.setItem("token", JSON.stringify(_data));
        //             try{
        //                 let decode = JSON.parse(atob(res.split('.')[1]))
        //                 this.optionTenant = decode.tenants
        //                 localStorage.setItem("tenants", JSON.stringify(this.optionTenant));
        //                 this.selectTenant = [ this.optionTenant[0].id ]
        //                 if(this.optionTenant.length > 1) {
        //                     this.modeForm = "tenant";
        //                 } else if (this.optionTenant.length == 1){
        //                     window.access_token = _data.access_token
        //                     window.refresh_token = _data.refresh_token
        //                     this.goTo()
        //                 }
        //             } catch {

        //             }
        //         })
        //         .catch((err) => {
        //             this.isError = "Terjadi kesalahan. Silahkan coba lagi atau hubungin administrator"
        //             if(err.status === 415) {
        //                   this.isError = "Username/Password yang Anda masukkan salah."
        //             } else if (err.status === 500) {
        //                 this.isError = 'Terjadi kesalahan. Silahkan coba lagi.'
        //             }
        //         })
        //         .finally((data) => (this.isLoading = false));
        // },
        changeEye() {
            if (this.eyeClass === "fa-eye-slash") {
                this.eyeClass = "fa-eye";
                this.isTypePassword = false;
            } else {
                this.eyeClass = "fa-eye-slash";
                this.isTypePassword = true;
            }
        },
        goTo() {
            localStorage.setItem("selectedTent", JSON.stringify(this.selectTenant[0]));
            window.tenantActive = this.selectTenant[0]
            if(this.$route.query && this.$route.query.redirect) {
               const decodeURI =  this.$route.query.redirect
               this.$router.push(decodeURI)
            } else {
                 this.$router.push({name: 'dashboard'})
            }
        },
        backTo() {
            this.modeForm = 'login'
        }
    },
};
</script>

<style scoped>
      @import "../assets/sass/login-v1.default.css";
</style>