<template>
  <div>
    <base-header base-title="Halaman Tidak Ditemukan"></base-header>
    <!-- begin:: Content -->
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 offset-md-3">
                <div class="kt-iconbox kt-iconbox--danger">
                  <div class="kt-iconbox__icon">
                    <div class="kt-iconbox__icon-bg"></div>
                    <i class="flaticon-background"></i>
                  </div>
                  <div class="kt-iconbox__title"><h1>404</h1></div>
                  <div
                    class="kt-iconbox__content"
                  >Maaf, halaman yang anda tuju tidak ditemukan (error 404)</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end:: Content -->
  </div>
</template>

<script>
import BaseHeader from "./../_base/BaseHeader";
import CheckPermission from "@/services/checkPermission.service";
const checkPermission = new CheckPermission();
export default {
  components: {
    BaseHeader
  },
  created () {
    const permission = this.checkPermission('BackOffice.View.Active.BOMSU')
    if(!permission) {
      this.$router.push({name: 'notSupport'})
    }
  },
  methods: {
     checkPermission: function(method) {
      return checkPermission.isCanAccess(method);
    },
  }
};
</script>
